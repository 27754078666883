.cinematic-v1_0 {
  @import "../shared/_defaults";
  @include display-header;
  @include origin-flag-simple;

  .display-header {
    background: linear-gradient(to bottom, rgba($black, .75), rgba($black, .5), rgba($black, 0));
  }

  .posts {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .post {
    font-size: 5vmin;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($black, .5);
    color: $white;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .media-module {
    position: relative;
    width: 100%;
    height: 100%;
    float: left;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  .blurred-media-module {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .media {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .text-module {
    position: absolute;
    width: 100%;
    height: auto;
    max-height: calc(100% - 25vmin);
    padding: 5vmin 5%;
    overflow: hidden;
  }

  .text .text-module, .chat .text-modulee {
    @include vertical-align;
  }

  .has-media .text-module {
    bottom: 0;
    left: 0;
    font-size: .8em;
    background: linear-gradient(to top, rgba($black, .75), rgba($black, .5), rgba($black, 0));
  }

  .meta-author {
    position: relative;
    font-size: .8em;
    padding-left: 2.75em;
    padding-right: 1.5em;
    margin-bottom: .5em;
  }

  .no-avatar {
    .meta-author {
      padding-left: 0;
    }
    .avatar {
      display: none;
    }
  }

  .avatar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    margin: auto;
    width: 2.25em;
    height: 2.25em;
    border-radius: 100%;
    box-shadow: 0 0 4px rgba($black, .5);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .user-img {
    visibility: hidden;
    width: 100%;
    height: 100%;
  }

  .author,
  .handle {
    @include ellipsis;
    float: left;
    clear: both;
    max-width: 100%;
    line-height: 2.25;
  }

  .twitter,
  .tiktok {
    .author,
    .handle {
      line-height: 1.3;
    }

    .handle {
      font-size: .8em;
    }
  }

  .reddit .subreddit {
    float: left;
    clear: both;
    max-width: 100%;
    line-height: 1.3;
    @include ellipsis;

    + .handle {
      font-size: .8em;
      line-height: 1.3;
    }
  }

  .post-time {
    display: none;
    font-size: .8em;
    margin-bottom: .5em;
    @include ellipsis;
  }

  .timestamp {
    font-size: .8em;
  }

  .post-title {
    margin-bottom: .5em;
    font-weight: bold;
  }

  .origin-flag {
    top: 5vmin;
    right: 5%;
  }
}
